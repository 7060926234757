import React, { useEffect, useState } from 'react';
import './Pricing.css'; // Custom styles without Bootstrap
import Navbar from "../navbar/Navbar";
import { useNavigate } from 'react-router-dom';

function Pricing() {
    const [subStatus, setSubStatus] = useState(null); // Initialize subscription status
    const navigate = useNavigate();

    // useEffect(() => {
    //     // Retrieve user data from localStorage
    //     const user = JSON.parse(localStorage.getItem("user"));
    //     if (user && user.sub_status !== undefined) {
    //         setSubStatus(user.sub_status);
    //     }
    //     // Log user object and subscription status for verification
    //     console.log("Subscription Status:", user.sub_status);
    //     console.log("User object:", user);
    // }, []);

    useEffect(() => {
        const fetchSubStatus = async () => {
            try {
                const token = localStorage.getItem("token");
                const email = localStorage.getItem("user_email");
                
                const response = await fetch("https://api.antsanalyzer.com/sub-status", {
                    method: "POST", // Use POST method
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`, // Authorization header
                    },
                    body: JSON.stringify({ email_id: email }), // Send email in body
                });

                if (response.ok) {
                    const data = await response.json();
                    setSubStatus(data.sub_status); // Set sub_status based on the API response
                } else {
                    console.error("Failed to fetch sub_status. Status:", response.status);
                }
            } catch (error) {
                console.error("Error fetching sub_status:", error);
            }
        };

        fetchSubStatus();
    }, []);

    const handleUpgradeClick = (plan) => {
        // Base prices without GST
        const basePrice = plan === 'pro' ? 250 : 1526;

        // Calculate the price including GST (18%)
        const finalPrice = (basePrice * 1.18).toFixed(2); // Adding 18% GST

        const pricingDetails = {
            planName: plan === 'pro' ? 'Pro' : 'Premium',
            price: finalPrice,  // Final price with GST
            billingPeriod: plan === 'pro' ? '3-days' : '1-year'
        };
        navigate('/checkout', { state: pricingDetails });
    };

    return (
        <div className='Pricing-Nav'>
            <Navbar />
            <div className="container">
                <header className='header-pricing'>
                    <div className="text-center custom-width my-4 mx-auto">
                        <h1>Pricing</h1>
                        <p className='fs-5 text-muted'>
                            Choose the plan that best fits your portfolio analysis needs. 
                            Whether you're just getting started or managing a complex portfolio, 
                            we offer tools and insights to help you optimize your investments and reach your financial goals.
                        </p> 
                    </div>
                </header>
                <main>
                    <div className="row">
                        {/* Free Plan */}
                        <div className="col">
                            <div className="card text-center position-relative">
                                {subStatus === 0 && <span className="active-badge">Active</span>}
                                <div className='card-header'>
                                    <h4 className='fw-normal'>Free</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title">
                                        ₹0 <small className="text-muted fw-light"></small>
                                    </h1>
                                    <ul className='list-unstyled py-3'>
                                        <li>Up to 3 portfolios</li>
                                        <li>Basic performance tracking</li>
                                        <li>Email support</li>
                                        <li>Learning Edition</li>
                                    </ul>
                                    <button className="btn btn-lg btn-success text-white w-100 btn-outline-success" 
                                        disabled={subStatus === 1 || subStatus === 2}>
                                        {subStatus === 0 ? "Your Current Plan" : "Free"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Pro Plan */}
                        <div className="col">
                            <div className="card text-center position-relative">
                                {subStatus === 1 && <span className="active-badge">Active</span>}
                                <div className='card-header'>
                                    <h4 className='fw-normal'>Pro</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title">
                                        ₹295 <small className="text-muted fw-light">/3 Days</small>
                                    </h1>
                                    <ul className='list-unstyled py-3'>
                                        <li>Upto 12 Scrips</li>
                                        <li>Advanced ROI Settings</li>
                                        <li>VAR/Crash Analysis Report</li>
                                        <li>Email support</li>
                                    </ul>
                                    <button className="btn btn-lg btn-success text-white w-100 btn-outline-success" 
                                        onClick={() => handleUpgradeClick('pro')} 
                                        disabled={subStatus === 2}>
                                        {subStatus === 1 ? "Your Current Plan" : "Upgrade to Pro"}
                                    </button>
                                </div>      
                            </div>
                        </div>

                        {/* Premium Plan */}
                        <div className="col">
                            <div className="card text-center position-relative">
                                {subStatus === 2 && <span className="active-badge">Active</span>}
                                <div className='card-header'>
                                    <h4 className='fw-normal'>Premium</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title">
                                        ₹1800 <small className="text-muted fw-light">/yr</small>
                                    </h1>
                                    <ul className='list-unstyled py-3'>
                                        <li>All Pro features</li>
                                        <li>Free Training on the tool</li>
                                        <li>Be a Co-Creator</li>
                                        <li>1 Year all new Upgrades free</li>
                                    </ul>
                                    <button className="btn btn-lg btn-success text-white w-100 btn-outline-success" 
                                        onClick={() => handleUpgradeClick('premium')} 
                                        disabled={subStatus === 2}>
                                        {subStatus === 2 ? "Your Current Plan" : "Upgrade to Premium"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                {/* Footer Links */}
                <footer className="footer-links">
                    <span>© Ants <strong>AI</strong></span>
                    <span className="footer-separator">|</span>
                    <a href="/terms-conditions" className="footer-link">Terms</a>
                    <a href="/privacy-policy" className="footer-link">Privacy</a>
                    <a href="/cancellation-refund-policy" className="footer-link">Return Policy</a>
                </footer>

            </div>
        </div>
    );
}

export default Pricing;
