import React, { useState } from 'react';
import './SubscribeModal.css';
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack"; // Import useSnackbar from notistack

const SubscribeModal = ({ showModal, closeModal }) => {
  const [activationKey, setActivationKey] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const { enqueueSnackbar } = useSnackbar(); // Initialize the useSnackbar hook
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const token = localStorage.getItem("token"); // Get token from local storage
  const email = localStorage.getItem("user_email"); // Get email from local storage

  // Handler to allow alphanumeric and special characters
  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    const allowedValue = value.replace(/[^A-Z0-9@#%&$*]/g, ''); // Allow alphanumeric and special characters @#%&$
    setActivationKey(allowedValue);
  };

  const handleActivation = async () => {
    if (activationKey.length < 8) {  // Optional: Adjust the length validation if needed
      enqueueSnackbar('Please enter a valid activation key', { variant: 'error' });
      return;
    }
  
    setLoading(true); // Start loading
  
    try {
      const url = "https://api.antsanalyzer.com/activate"; // Endpoint for activation
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Bearer token from local storage
        },
        body: JSON.stringify({
          email_id: email, // Email from local storage
          activation_key: activationKey, // Activation key entered by the user
        }),
      };
  
      const response = await fetch(url, requestOptions);
  
      if (response.ok) {
        const result = await response.json();
        setLoading(false); // Stop loading
  
        // Show success message
        enqueueSnackbar('Subscription activated successfully!', { variant: 'success' });
  
        // Optionally, close the modal or navigate to another page
        closeModal();
  
        // Set a timeout of 1 second (1000 ms) before refreshing the page
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 1000);
  
      } else {
        // Show error message if the request failed
        const errorBody = await response.json();
        enqueueSnackbar(errorBody.message || 'Failed to activate subscription', { variant: 'error' });
        setLoading(false); // Stop loading on error
      }
    } catch (error) {
      console.error('Error activating subscription:', error);
      enqueueSnackbar('Error activating subscription. Please try again.', { variant: 'error' });
      setLoading(false); // Stop loading on error
    }
  };

  const handleGoToPaymentPage = () => {
    navigate('/pricing'); // Assuming that the route for Pricing.js is '/pricing'
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="subscribe-modal">
      <div className="subscribe-modal-content">
        <button className="subscribe-close-button" onClick={closeModal}>
          ×
        </button>
        <h2 className="subscribe-title">Subscribe</h2>
        <div className="subscribe-input-container">
          <div className="subscribe-input-row">
            <label className="subscribe-label">Enter Your Activation Key</label>
            <input
              type="text"
              className="subscribe-input"
              maxLength={8}  // You can adjust maxLength if required
              value={activationKey}
              onChange={handleInputChange}
              placeholder="8-Digit Activation Key"
            />
          </div>
          <div className="subscribe-input-row">
            <label className="subscribe-label">Subscription Activation</label>
            <button className="subscribe-activate-button" onClick={handleActivation} disabled={loading}>
              {loading ? <TailSpin height="20" width="20" color="#ffffff" ariaLabel="loading" /> : 'Activate'}
            </button>
          </div>
        </div>

        <div className="subscribe-or-container">
          <p className="subscribe-or-note">
            <strong>or</strong>
          </p>
        </div>

        <div className="subscribe-submit-button-container">
          <button className="subscribe-submit-button" onClick={handleGoToPaymentPage}>
            Go to Payment Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
