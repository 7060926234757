import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import AntsAi from "../../assets/Ants.png";
import AnalyserIcon from "../../assets/analyser.svg";
import ReportIcon from "../../assets/report.png";
import ActiveNavIcon from "../../assets/activeicon.svg";
import NotificationIcon from "../../assets/bell_icon.svg";
import helpIcon from "../../assets/BookReadIcon.svg";
import stressIcon from "../../assets/stress.svg";
import logoutIcon from "../../assets/logout.svg";
import menuIcon from "../../assets/menu.svg";
import moneyIcon from "../../assets/money.svg"; // Import money icon
import Sidebar from "./Sidebar";
import UserManual from "../../assets/PDF user manual Ants Analyzer ver 1.0.pdf";

const Navbar = ({ notificationCount }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(location.pathname);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const profileMenuRef = useRef(null);

  useEffect(() => {
    if (!user) {
      const fetchUserData = async () => {
        const token = localStorage.getItem("token");
        const email = localStorage.getItem("user_email");

        try {
          const response = await fetch("https://api.antsanalyzer.com/profile", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email_id: email }),
          });

          if (response.ok) {
            const data = await response.json();
            setUser(data.data);
            localStorage.setItem("user", JSON.stringify(data.data));
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [user]);

  const handleNavClick = (navItem) => {
    setActiveNav(navItem);
  };

  const handleProfileClick = (tab = "") => {
    navigate(`/profile${tab ? `?tab=${tab}` : ""}`);
    setIsProfileMenuOpen(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_email");
    setUser(null);
    navigate("/signin");
  };

  const handleUserIconClick = () => {
    if (window.innerWidth > 768) {
      setIsProfileMenuOpen((prev) => !prev);
    } else {
      navigate("/profile");
    }
  };

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setIsProfileMenuOpen(false);
    }
  };

  const handleHelpIconClick = () => {
    const link = document.createElement("a");
    link.href = UserManual;
    link.download = "Ants_Analyser_User_Manual_Ver_1.0.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="navbar">
      <div className="menu-icon-container">
        <img
          src={menuIcon}
          alt="Menu"
          className="menu-icon"
          onClick={toggleSidebar}
        />
      </div>
      <img src={AntsAi} alt="AntsAI" className="logo" />
      <nav className="nav-links">
        <Link
          to="/analyser"
          className={`nav-item ${activeNav === "/analyser" ? "active" : ""}`}
          onClick={() => handleNavClick("/analyser")}
        >
          <img src={AnalyserIcon} alt="Analyser" className="icon" />
          Analyzer
          {activeNav === "/analyser" && (
            <img src={ActiveNavIcon} alt="Active" className="active-icon" />
          )}
        </Link>
        {/* <Link
          to="/stresstest"
          className={`nav-item ${activeNav === "/stresstest" ? "active" : ""}`}
          onClick={() => handleNavClick("/stresstest")}
        >
          <img src={stressIcon} alt="Stress Test" className="icon" />
          Stress Test
          {activeNav === "/stresstest" && (
            <img src={ActiveNavIcon} alt="Active" className="active-icon" />
          )}
        </Link> */}
        <Link
          to="/report"
          className={`nav-item ${activeNav === "/report" ? "active" : ""}`}
          onClick={() => handleNavClick("/report")}
        >
          <img src={ReportIcon} alt="Reports" className="icon" />
          Reports
          {activeNav === "/report" && (
            <img src={ActiveNavIcon} alt="Active" className="active-icon" />
          )}
        </Link>
        <Link
          to="/pricing"
          className={`nav-item ${
            activeNav === "/pricing" ? "active" : ""
          }`}
          onClick={() => handleNavClick("/pricing")}
        >
          <img src={moneyIcon} alt="Subscription" className="icon" />
          Subscription
          {activeNav === "/pricing" && (
            <img src={ActiveNavIcon} alt="Active" className="active-icon" />
          )}
        </Link>
      </nav>
      <div className="nav-icons">
        <div className="help-icon-container" onClick={handleHelpIconClick}>
          <img src={helpIcon} alt="Help" className="help-icon" />
          <div className="help-label">User Manual</div>
        </div>
        <div
          className="notification-container"
          onClick={() => handleProfileClick("notification")}
        >
          <img
            src={NotificationIcon}
            alt="Notification"
            className="notification-icon"
          />
          {notificationCount > 0 && (
            <span className="notification-badge">{notificationCount}</span>
          )}
        </div>
        {user ? (
          <div
            className="user-icon"
            onClick={handleUserIconClick}
            ref={profileMenuRef}
          >
            {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
            {isProfileMenuOpen && (
              <div className="profile-menu">
                <div
                  className="profile-menu-item"
                  onClick={() => handleProfileClick()}
                >
                  Profile
                </div>
                <div
                  className="profile-menu-item"
                  onClick={() => handleProfileClick("subscription")}
                >
                  Subscription
                </div>
                <div
                  className="profile-menu-item"
                  onClick={() => handleProfileClick("help")}
                >
                  Help
                </div>
                <div className="profile-menu-item" onClick={handleSignOut}>
                  <img src={logoutIcon} alt="Logout" className="logout-icon" />{" "}
                  Sign Out
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="user-icon-placeholder"></div>
        )}
      </div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Navbar;
