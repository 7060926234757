import React, { createContext, useState } from "react";

export const ScripContext = createContext();

export const ScripProvider = ({ children }) => {
  const [scrips, setScrips] = useState(Array(12).fill("Scrip Name"));
  const [quantities, setQuantities] = useState(Array(12).fill(null));
  const [selectedIndices, setSelectedIndices] = useState(Array(12).fill(""));
  const [closingPrices, setClosingPrices] = useState(Array(12).fill("-")); // Add closing prices
  const [categories, setCategories] = useState(Array(12).fill("")); // Add categories
  const [expectedReturns, setExpectedReturns] = useState(Array(12).fill(0)); // Add expected returns
  const [variances, setVariances] = useState(Array(12).fill(0)); // Add variances

  return (
    <ScripContext.Provider
      value={{
        scrips,
        setScrips,
        quantities,
        setQuantities,
        selectedIndices,
        setSelectedIndices,
        closingPrices, // Provide closing prices
        setClosingPrices, // Provide setter for closing prices
        categories, // Provide categories
        setCategories, // Provide setter for categories
        expectedReturns, // Provide expected returns
        setExpectedReturns, // Provide setter for expected returns
        variances, // Provide variances
        setVariances, // Provide setter for variances
      }}
    >
      {children}
    </ScripContext.Provider>
  );
};
