// import React, { useState } from 'react';
// import QRCodeProImage from '../../assets/295qrcode.jpg';
// import QRCodePremiumImage from '../../assets/1800qrcode.jpg';
// import './QRPage.css';
// import { useLocation, useNavigate } from "react-router-dom";

// function QRPage() {
//     const location = useLocation();
//     const { planName, price } = location.state || { planName: 'Pro', price: 295 }; // Default to Pro if no state passed
//     const [transactionId, setTransactionId] = useState('');
//     const navigate = useNavigate();

//     // Choose the appropriate QR code image based on the plan
//     const qrCodeImage = planName === 'Pro' ? QRCodeProImage : QRCodePremiumImage;

//     const handleDownloadQR = () => {
//         const link = document.createElement('a');
//         link.href = qrCodeImage;
//         link.download = planName === 'Pro' ? '295qrcode.jpg' : '1800qrcode.jpg';
//         link.click();
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log('Transaction ID Submitted:', transactionId);
//         navigate('/thank-you');
//     };

//     return (
//         <div className="qr-page-container">
//             <div className="qr-card">
//                 {/* QR Code Section */}
//                 <div className="qr-code-section">
//                     <img src={qrCodeImage} alt="QR Code" className="qr-image" />
//                     <p>UPI ID: vijaypura@okaxis</p>
//                     <p>Amount: ₹{price}.00</p> {/* Display the correct price */}
//                     <button onClick={handleDownloadQR} className="qr-download-btn">
//                         Download QR Code
//                     </button>
//                 </div>

//                 {/* Payment Details Section */}
//                 <div className="qr-payment-details">
//                     <p className='transactionid-p'>After payment, submit your transaction ID below.</p>
//                     <form onSubmit={handleSubmit} className="qr-form">
//                         <input
//                             type="text"
//                             placeholder="Enter Transaction ID"
//                             value={transactionId}
//                             onChange={(e) => setTransactionId(e.target.value)}
//                             className="qr-input"
//                             required
//                         />
//                         <button type="submit" className="qr-submit-btn">Submit</button>
//                     </form>
//                     <h3 className="qr-header">Please read carefully:</h3>
//                     <p className='note-p'>
//                         Once the payment is done and the user enters the transaction details, and the payment is confirmed, an activation code will be sent to your email ID.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default QRPage;


import React, { useState } from 'react';
import QRCodeProImage from '../../assets/295qrcode.jpg';
import QRCodePremiumImage from '../../assets/1800qrcode.jpg';
import './QRPage.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack'; // Import the snackbar for notifications

function QRPage() {
    const location = useLocation();
    const { planName, price } = location.state || { planName: 'Pro', price: 295 }; // Default to Pro if no state passed
    const [transactionId, setTransactionId] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar(); // Initialize snackbar for feedback
    const navigate = useNavigate();

    // Choose the appropriate QR code image based on the plan
    const qrCodeImage = planName === 'Pro' ? QRCodeProImage : QRCodePremiumImage;

    // Function to handle QR code download
    const handleDownloadQR = () => {
        const link = document.createElement('a');
        link.href = qrCodeImage;
        link.download = planName === 'Pro' ? '295qrcode.jpg' : '1800qrcode.jpg';
        link.click();
    };

    // Transaction ID change handler (Validation)
    const handleTransactionIdChange = (e) => {
        const value = e.target.value.toUpperCase();
        const regex = /^[A-Z0-9]{0,23}$/; // Same regex for alphanumeric uppercased IDs

        if (regex.test(value)) {
            setTransactionId(value);
        }
    };

    // Submit transaction ID and payment details to backend
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission

        setLoading(true);
        const token = localStorage.getItem("token");
        const email = localStorage.getItem("user_email");

        const requestData = {
            email_id: email,
            transaction_id: transactionId,
            payment_fee: price, // Use the price dynamically based on the plan selected
        };

        try {
            const response = await fetch("https://api.antsanalyzer.com/payment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setLoading(false);
                enqueueSnackbar(
                    "Payment process completed. We will verify your Transaction ID, please wait 24 hours for your reports!",
                    {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "left" },
                        autoHideDuration: 4000,
                    }
                );
                navigate("/thank-you");
            } else {
                setLoading(false);
                enqueueSnackbar(
                    "Failed to complete the payment process. Please try again.",
                    { variant: "error" }
                );
            }
        } catch (error) {
            setLoading(false);
            console.error("Error submitting payment:", error);
            enqueueSnackbar("Error submitting payment. Please try again later.", {
                variant: "error",
            });
        }
    };

    return (
        <div className="qr-page-container">
            <div className="qr-card">
                {/* QR Code Section */}
                <div className="qr-code-section">
                    <img src={qrCodeImage} alt="QR Code" className="qr-image" />
                    <p>UPI ID: vijaypura@okaxis</p>
                    <p>Amount: ₹{price}.00</p> {/* Display the correct price */}
                    <button onClick={handleDownloadQR} className="qr-download-btn">
                        Download QR Code
                    </button>
                </div>

                {/* Payment Details Section */}
                <div className="qr-payment-details">
                    <p className='transactionid-p'>After payment, submit your transaction ID below.</p>
                    <form onSubmit={handleSubmit} className="qr-form">
                        <input
                            type="text"
                            placeholder="Enter Transaction ID"
                            value={transactionId}
                            onChange={handleTransactionIdChange}
                            className="qr-input"
                            required
                        />
                        <button type="submit" className="qr-submit-btn" disabled={loading}>
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </form>
                    <h3 className="qr-header">Please read carefully:</h3>
                    <p className='note-p'>
                        Once the payment is done and the user enters the transaction details, and the payment is confirmed, an activation code will be sent to your email ID.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default QRPage;

