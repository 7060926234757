import React, { useState } from "react";
import "./Signin.css";
import AntsAi from "../assets/Ants.png";
import Img1 from "../assets/ApA2.png";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TailSpin } from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyChecked, setVerifyChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for showing/hiding password

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(userID)) {
      enqueueSnackbar("Invalid email format.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      setLoading(false);
      return;
    }

    const loginData = {
      user_email: userID,
      password: password,
    };

    try {
      const response = await fetch("https://api.antsanalyzer.com/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Login successful:", data);
        enqueueSnackbar("Logged in Successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1000,
        });
         // Save token and full user object to localStorage
    localStorage.setItem("token", data.token);
    localStorage.setItem("user_email", data.user.user_email);
    localStorage.setItem("user", JSON.stringify(data.user)); // Store full user object with sub_status

    if (!localStorage.getItem("isNotNewUser")) {
        localStorage.setItem("isNotNewUser", "true");
    }

        // Send login event to Google Analytics
        if (window.gtag) {
          window.gtag("event", "login", {
            method: "Email", // Specify method (e.g., 'Google', 'Facebook')
            user_email: userID, // Optional: Send user-related info
          });
        }

        setLoading(false);
        navigate("/analyser");
      } else {
        const errorData = await response.json();
        enqueueSnackbar(errorData.message || "Incorrect Email or Password.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      enqueueSnackbar("An unexpected error occurred. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <img src={Img1} alt="Logo" className="login-leftlogo1" />
      </div>
      <div className="login-right">
        <form className="login-form" onSubmit={handleLogin}>
          <img src={AntsAi} alt="AntsAI" />
          <h2 className="login-h2">Login</h2>
          <div className="login-input-group">
            <label>
              User ID <span className="login-mandatory">*</span>
            </label>
            <input
              type="text"
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
              required
            />
          </div>
          <div className="login-input-group">
            <label>
              Password <span className="login-mandatory">*</span>
            </label>
            <div className="login-password-field">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="login-toggle-password-visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="login-forgot-password">
            <Link to="/forgotPassword">Forgot Password?</Link>
          </div>
          <div className="login-checkbox-container">
            <input
              type="checkbox"
              id="verifyCheckbox"
              className="login-verify-checkbox"
              checked={verifyChecked}
              onChange={() => setVerifyChecked(!verifyChecked)}
            />
            <label
              htmlFor="verifyCheckbox"
              className="login-verify-checkbox-label"
            >
              Verify that you're not a robot
            </label>
          </div>

          <button
            type="submit"
            className="login-button"
            disabled={!verifyChecked || isSubmitting}
          >
            {loading ? (
              <TailSpin
                height="20"
                width="20"
                color="#ffffff"
                ariaLabel="loading"
              />
            ) : (
              "Login"
            )}
          </button>
          <div className="login-signup-link">
            <Link to="/signup">Don't have an account? Sign Up</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;