import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import AntsAi from "../../assets/Ants.png";
import AnalyserIcon from "../../assets/analyser.svg";
import ReportIcon from "../../assets/report.png";
import StressIcon from "../../assets/stress.svg";
import HelpIcon from "../../assets/BookReadIcon.svg";
import LogoutIcon from "../../assets/logout.svg";
import closeMenuIcon from "../../assets/closemenu.svg";
import moneyIcon from "../../assets/money.svg"; // Import money icon
import UserManual from "../../assets/PDF user manual Ants Analyzer ver 1.0.pdf";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const sidebarRef = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_email");
    window.location.href = "/signin";
  };

  const handleDownloadGuide = () => {
    const link = document.createElement("a");
    link.href = UserManual;
    link.download = "Ants_Analyser_User_Manual_Ver_1.0.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={sidebarRef}
      className={`sidebar-sidebar ${isOpen ? "sidebar-open" : ""}`}
    >
      <div>
        <div className="sidebar-header">
          <img
            src={AntsAi}
            alt="AntsAI"
            className="sidebar-logo sidebar-ants-logo"
          />
          <img
            src={closeMenuIcon}
            alt="Close Menu"
            className="sidebar-menu-icon"
            onClick={toggleSidebar}
          />
        </div>
        <div className="sidebar-menu">
          <Link
            to="/analyser"
            className={`sidebar-item ${
              location.pathname === "/analyser" ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <img src={AnalyserIcon} alt="Analyser" />
            <span>Analyzer</span>
          </Link>
          {/* <Link
            to="/stresstest"
            className={`sidebar-item ${
              location.pathname === "/stresstest" ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <img src={StressIcon} alt="Stress Test" />
            <span>Stress Test</span>
          </Link> */}
          <Link
            to="/report"
            className={`sidebar-item ${
              location.pathname === "/report" ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <img src={ReportIcon} alt="Reports" />
            <span>Reports</span>
          </Link>
          <Link
            to="/pricing"
            className={`sidebar-item ${
              location.pathname === "/pricing" ? "active" : ""
            }`}
            onClick={toggleSidebar}
          >
            <img src={moneyIcon} alt="Subscription" />
            <span>Subscription</span>
          </Link>
        </div>
      </div>
      <div className="sidebar-footer">
        <div className="sidebar-item" onClick={handleDownloadGuide}>
          <img src={HelpIcon} alt="Guide" />
          <span className="download-guide">User Manual</span>
        </div>
        <div className="sidebar-item" onClick={handleLogout}>
          <img src={LogoutIcon} alt="Logout" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
