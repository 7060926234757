import React, { useState } from 'react';
import './Checkout.css'; // Custom styles for the checkout page
import { useNavigate, useLocation } from 'react-router-dom';
import AntsLogo from "../../assets/Ants-logo.png";

function Checkout() {
    const navigate = useNavigate();
    const location = useLocation();

    // Use the state from navigation to populate the checkout details
    const { planName, price, billingPeriod } = location.state || {
        planName: 'Pro',
        price: 295,
        billingPeriod: '3-days'
    };

    // Parse the price to a float for calculations
    const basePrice = (planName === 'Pro' ? 250 : 1526).toFixed(2); // Base price without GST
    const gstAmount = (basePrice * 0.18).toFixed(2); // GST is 18%
    const totalPrice = (parseFloat(basePrice) + parseFloat(gstAmount)).toFixed(2); // Total price = base price + GST

    // Retrieve email from localStorage
    const [formData, setFormData] = useState({
        address: '',
        addressLine2: '',
        city: '',
        pin: '',
        state: '',
        country: 'India',
        email: localStorage.getItem("user_email") || '', // Automatically populate email
        phoneNumber: '',
        agreeTerms: false,
    });

    const [errors, setErrors] = useState({});

    // Validate Indian phone number (10 digits)
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[6-9]\d{9}$/; // Indian numbers starting with 6-9, followed by 9 digits
        return phoneRegex.test(phoneNumber);
    };

    // Validate Indian PIN code (6 digits)
    const isValidPinCode = (pin) => {
        const pinRegex = /^\d{6}$/; // 6 digits
        return pinRegex.test(pin);
    };

    // PIN code to state mapping based on first two digits
    const pinToStateMap = {
        // Delhi
        "11": "Delhi",

        // Maharashtra (40XXXX - 44XXXX)
        "40": "Maharashtra", "41": "Maharashtra", "42": "Maharashtra", "43": "Maharashtra", "44": "Maharashtra",

        // Karnataka (560XXX - 59XXXX)
        "56": "Karnataka", "57": "Karnataka", "58": "Karnataka", "59": "Karnataka",

        // Tamil Nadu (60XXXX - 64XXXX)
        "60": "Tamil Nadu", "61": "Tamil Nadu", "62": "Tamil Nadu", "63": "Tamil Nadu", "64": "Tamil Nadu",

        // Gujarat (36XXXX - 39XXXX)
        "36": "Gujarat", "37": "Gujarat", "38": "Gujarat", "39": "Gujarat",

        // West Bengal (70XXXX - 74XXXX)
        "70": "West Bengal", "71": "West Bengal", "72": "West Bengal", "73": "West Bengal", "74": "West Bengal",

        // Uttar Pradesh (20XXXX - 28XXXX)
        "20": "Uttar Pradesh", "21": "Uttar Pradesh", "22": "Uttar Pradesh", "23": "Uttar Pradesh", 
        "24": "Uttar Pradesh", "25": "Uttar Pradesh", "26": "Uttar Pradesh", "27": "Uttar Pradesh", 
        "28": "Uttar Pradesh",

        // Rajasthan (30XXXX - 34XXXX)
        "30": "Rajasthan", "31": "Rajasthan", "32": "Rajasthan", "33": "Rajasthan", "34": "Rajasthan",

        // Punjab (14XXXX - 15XXXX)
        "14": "Punjab", "15": "Punjab",

        // Madhya Pradesh (45XXXX - 48XXXX)
        "45": "Madhya Pradesh", "46": "Madhya Pradesh", "47": "Madhya Pradesh", "48": "Madhya Pradesh",

        // Bihar (80XXXX - 85XXXX)
        "80": "Bihar", "81": "Bihar", "82": "Bihar", "83": "Bihar", "84": "Bihar", "85": "Bihar",

        // Telangana (50XXXX)
        "50": "Telangana",

        // Kerala (67XXXX - 69XXXX)
        "67": "Kerala", "68": "Kerala", "69": "Kerala",

        // Haryana (12XXXX - 13XXXX)
        "12": "Haryana", "13": "Haryana",

        // Andhra Pradesh (51XXXX - 53XXXX)
        "51": "Andhra Pradesh", "52": "Andhra Pradesh", "53": "Andhra Pradesh",

        // Odisha (75XXXX - 77XXXX)
        "75": "Odisha", "76": "Odisha", "77": "Odisha",

        // Assam (78XXXX)
        "78": "Assam",

        // Goa (403XXX)
        "403": "Goa",

        // Jammu & Kashmir and Ladakh (18XXXX - 19XXXX)
        "18": "Jammu & Kashmir", "19": "Jammu & Kashmir and Ladakh",

        // Himachal Pradesh (17XXXX)
        "17": "Himachal Pradesh",

        // Uttarakhand (24XXXX)
        "24": "Uttarakhand",

        // Chhattisgarh (49XXXX)
        "49": "Chhattisgarh",

        // Jharkhand (83XXXX)
        "83": "Jharkhand",

        // Sikkim (73XXXX)
        "73": "Sikkim",

        // Arunachal Pradesh (79XXXX)
        "79": "Arunachal Pradesh",

        // Manipur (79XXXX)
        "79": "Manipur",

        // Meghalaya (79XXXX)
        "79": "Meghalaya",

        // Mizoram (79XXXX)
        "79": "Mizoram",

        // Nagaland (79XXXX)
        "79": "Nagaland",

        // Tripura (79XXXX)
        "79": "Tripura",

        // Andaman & Nicobar Islands (744XXX)
        "744": "Andaman & Nicobar Islands",

        // Lakshadweep (682XXX)
        "682": "Lakshadweep"
    };

    // Automatically populate the state based on the PIN code
    const handlePinCodeChange = (e) => {
        const pin = e.target.value;
        setFormData({
            ...formData,
            pin: pin,
        });

        const pinPrefix = pin.substring(0, 2); // Get the first 2 digits

        if (isValidPinCode(pin) && pinToStateMap[pinPrefix]) {
            setFormData((prevData) => ({
                ...prevData,
                state: pinToStateMap[pinPrefix], // Populate state based on the prefix
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                state: '', // Clear state if PIN is invalid
            }));
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const token = localStorage.getItem("token");
        const email = localStorage.getItem("user_email");
        const subDetails = {
            email_id: email,  // Automatically retrieved from localStorage
            sub_details: {
                planName,
                price: parseFloat(basePrice), // Sending base price without GST
                billingPeriod,
                address: formData.address,
                addressLine2: formData.addressLine2,
                city: formData.city,
                pin: formData.pin,
                state: formData.state,
                country: formData.country,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
            }
        };

        try {
            const response = await fetch('https://api.antsanalyzer.com/sub-details', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(subDetails),
            });

            if (response.ok) {
                console.log('Subscription details submitted successfully');
                navigate('/qr', { state: { planName, price: totalPrice } });
            } else {
                console.error('Failed to submit subscription details', response.status);
            }
        } catch (error) {
            console.error('Error submitting subscription details:', error);
        }
    };

    // Form validation
    const validateForm = () => {
        let formErrors = {};

        if (!formData.address) formErrors.address = "Address is required";
        if (!formData.city) formErrors.city = "City is required";
        if (!formData.pin) {
            formErrors.pin = "PIN is required";
        } else if (!isValidPinCode(formData.pin)) {
            formErrors.pin = "Invalid PIN code";
        }
        if (!formData.state) formErrors.state = "State is required";
        if (!formData.phoneNumber) {
            formErrors.phoneNumber = "Phone number is required";
        } else if (!isValidPhoneNumber(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number must be 10 digits";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    return (
        <div className="checkout-page">
            <div className="checkout-container">
                {/* Left Side: Subscription Details */}
                <div className="checkout-left">
                    <div className="checkout-back-logo-container">
                        <div className="checkout-back-arrow" onClick={() => navigate(-1)}>
                            &larr;
                        </div>
                        <div className="checkout-logo-section">
                            <img src={AntsLogo} alt="Ants Logo" className="checkout-logo" />
                            <span className="checkout-logo-text">Ants AI</span>
                        </div>
                    </div>

                    <div className="checkout-price-container">
                        <h2 className="checkout-header-title">Subscribe to Ants Analyzer {planName} Subscription</h2>
                        <div className="checkout-price-details">
                            <span className="checkout-price-value">₹{totalPrice}</span>
                            <div className="checkout-price-period">
                                <span>/ {billingPeriod}</span>
                            </div>
                        </div>
                    </div>

                    <div className="checkout-subscription-details">
                        <span className="checkout-subscription-details-title">Ants Analyzer {planName} Subscription</span>
                        <span className="checkout-subscription-billed">Billed for {billingPeriod}</span>
                    </div>

                    {/* Pricing Summary with GST Breakdown */}
                    <div className="checkout-pricing-summary">
                        <div className="checkout-pricing-row">
                            <span>Base Price</span>
                            <span>₹{basePrice}</span> {/* Display base price without GST */}
                        </div>
                        <div className="checkout-pricing-row">
                            <span>GST (18%)</span>
                            <span>₹{gstAmount}</span> {/* Display GST Amount */}
                        </div>
                    </div>

                    <div className="checkout-pricing-row total">
                        <span>Total</span>
                        <span>₹{totalPrice}</span> {/* Display Total Amount (base price + GST) */}
                    </div>
                </div>

                {/* Right Side: Payment Form */}
                <div className="checkout-right">
                    <form className="checkout-form" onSubmit={handleSubmit}>
                        <h3 className="checkout-form-header">Contact Information</h3>
                        <div className="checkout-form-group">
                            <label>Email <span className="checkout-required">*</span></label>
                            <input 
                                type="text" 
                                name="email"
                                placeholder="Abc@gmail.com"
                                value={formData.email} 
                                onChange={handleInputChange} 
                                className="checkout-input" 
                                required
                            />
                        </div>

                        <h3 className="checkout-form-header">Billing Address</h3>
                        <div className="checkout-form-group">
                            <label>Country <span className="checkout-required">*</span></label>
                            <select name="country" value={formData.country} className="checkout-input" disabled>
                                <option value="India">India</option>
                            </select>
                        </div>
                        <div className="checkout-form-group">
                            <label>Address <span className="checkout-required">*</span></label>
                            <input
                                type="text"
                                name="address"
                                placeholder="Address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className="checkout-input"
                                required
                            />
                            {errors.address && <div className="checkout-error">{errors.address}</div>}
                        </div>
                        <div className="checkout-form-group">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                name="addressLine2"
                                placeholder="Address line 2 (Optional)"
                                value={formData.addressLine2}
                                onChange={handleInputChange}
                                className="checkout-input"
                            />
                        </div>
                        <div className="checkout-form-group checkout-city-pin">
                            <div>
                                <label>City <span className="checkout-required">*</span></label>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    className="checkout-input"
                                    required
                                />
                                {errors.city && <div className="checkout-error">{errors.city}</div>}
                            </div>
                            <div>
                                <label>PIN <span className="checkout-required">*</span></label>
                                <input
                                    type="text"
                                    name="pin"
                                    placeholder="PIN"
                                    value={formData.pin}
                                    onChange={handlePinCodeChange}  // Handle PIN code change
                                    className="checkout-input"
                                    required
                                    maxLength={6}  // Set the maximum length to 6 digits
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 6)}  // Ensure no more than 6 digits can be entered
                                />
                                {errors.pin && <div className="checkout-error">{errors.pin}</div>}
                            </div>
                        </div>
                        <div className="checkout-form-group">
                            <label>State <span className="checkout-required">*</span></label>
                            <input
                                type="text"
                                name="state"
                                placeholder="State"
                                value={formData.state}
                                onChange={handleInputChange}
                                className="checkout-input"
                                required
                                readOnly // Automatically filled based on PIN
                            />
                            {errors.state && <div className="checkout-error">{errors.state}</div>}
                        </div>

                        <div className="checkout-form-group checkout-save-info">
                            <label>Phone Number <span className="checkout-required">*</span></label>
                            <input
                                type="text"
                                name="phoneNumber"
                                placeholder="081234 56789"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                className="checkout-input"
                                maxLength={10} 
                                required
                            />
                            {errors.phoneNumber && <div className="checkout-error">{errors.phoneNumber}</div>}
                        </div>

                        <div className="checkout-form-group checkout-checkbox">
                            <input
                                type="checkbox"
                                name="agreeTerms"
                                checked={formData.agreeTerms}
                                onChange={handleCheckboxChange}
                                required
                            />
                            <label>
                                You'll be charged the amount listed above. By subscribing, you agree to Ants AI's <a href="/terms-conditions">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>.
                            </label>
                        </div>

                        <button
                            type="submit"
                            className={`checkout-submit-btn ${!formData.agreeTerms ? 'disabled' : ''}`}
                            disabled={!formData.agreeTerms}
                        >
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
