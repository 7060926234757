import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Profile.css";
import Navbar from "./Navbar";
import profileIcon from "../../assets/profile-icon.svg";
import notificationIcon from "../../assets/notification-icon.svg";
import subscriptionIcon from "../../assets/subscription-icon.svg";
import helpIcon from "../../assets/help-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TailSpin } from "react-loader-spinner";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_email");
    enqueueSnackbar("Unauthorised access, Please login", {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    navigate("/signin");
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("user_email");

      try {
        // First: Fetch Profile Data
        const profileResponse = await fetch("https://api.antsanalyzer.com/profile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email_id: email }),
        });

        if (profileResponse.status === 403) {
          handleLogout();
          return;
        }

        if (profileResponse.ok) {
          const profileData = await profileResponse.json();
          setUser(profileData.data);

          const startDate = new Date(profileData.data.valid_start_date);
          const endDate = new Date(profileData.data.valid_end_date);

          const formatDate = (date) => {
            return date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          };

          // Second: Fetch Subscription Status
          const subStatusResponse = await fetch("https://api.antsanalyzer.com/sub-status", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email_id: email }),
          });

          if (subStatusResponse.status === 403) {
            handleLogout();
            return;
          }

          if (subStatusResponse.ok) {
            const subStatusData = await subStatusResponse.json();

            // Determine the plan and status from sub_status
            let plan = "Free";
            let status = "Inactive";

            switch (subStatusData.sub_status) {
              case 1:
                plan = "Pro";
                status = "Active";
                break;
              case 2:
                plan = "Premium";
                status = "Active";
                break;
              default:
                plan = "Free";
                status = "Inactive";
            }

            setSubscription({
              status,
              plan,
              startDate: plan !== "Free" ? formatDate(startDate) : null,
              renewalDate: plan !== "Free" ? formatDate(endDate) : null,
            });
          } else {
            console.error("Failed to fetch subscription status");
          }
        } else {
          console.error("Failed to fetch profile data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    switch (tab) {
      case "subscription":
        setSelectedTabIndex(1);
        break;
      case "notification":
        setSelectedTabIndex(2);
        break;
      case "help":
        setSelectedTabIndex(3);
        break;
      default:
        setSelectedTabIndex(0);
        break;
    }
  }, [location.search]);

  const addNotification = (message, time) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id: prevNotifications.length + 1, message, time, isChecked: false },
    ]);
  };

  const handleCheckboxChange = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id
          ? { ...notification, isChecked: !notification.isChecked }
          : notification
      )
    );
  };

  const handleDelete = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  if (loading) {
    return (
      <div className="loader-backdrop">
        <div className="loader-container">
          <TailSpin color="#ff8300" height={80} width={80} />
        </div>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <Navbar notificationCount={notifications.length} />
      <div className="profile-container">
        <h1 className="profile-title">Hi! {user && user.first_name}</h1>
        <Tabs
          selectedIndex={selectedTabIndex}
          onSelect={(index) => setSelectedTabIndex(index)}
        >
          <TabList className="profile-tab-list">
            <Tab className="profile-tab">
              <img src={profileIcon} alt="Profile" className="tab-icon" />{" "}
              Profile
            </Tab>
            <Tab className="profile-tab">
              <img
                src={subscriptionIcon}
                alt="Subscription"
                className="tab-icon"
              />{" "}
              Subscription
            </Tab>
            <Tab className="profile-tab">
              <img
                src={notificationIcon}
                alt="Notification"
                className="tab-icon"
              />{" "}
              Notification
            </Tab>
            <Tab className="profile-tab">
              <img src={helpIcon} alt="Help" className="tab-icon" /> Help
            </Tab>
          </TabList>

          <TabPanel>
            <div className="profile-info">
              <div className="profile-info-item">
                <label className="profile-info-label">First Name</label>
                <input
                  type="text"
                  value={user && user.first_name}
                  readOnly
                  className="profile-info-input"
                />
              </div>
              <div className="profile-info-item">
                <label className="profile-info-label">Last Name</label>
                <input
                  type="text"
                  value={user && user.last_name}
                  readOnly
                  className="profile-info-input"
                />
              </div>
              <div className="profile-info-item">
                <label className="profile-info-label">E-mail</label>
                <input
                  type="text"
                  value={user && user.user_email}
                  readOnly
                  className="profile-info-input"
                />
              </div>
              <div className="profile-info-item">
                <label className="profile-info-label">Phone</label>
                <input
                  type="text"
                  value={user && user.mobile_no}
                  readOnly
                  className="profile-info-input"
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="subscription-info">
              <div className="subscription-info-item">
                <label className="subscription-info-label">Status</label>
                <input
                  type="text"
                  value={subscription.status}
                  readOnly
                  className={`subscription-info-input ${
                    subscription.status === "Active" ? "active" : "inactive"
                  }`}
                />
              </div>
              <div className="subscription-info-item">
                <label className="subscription-info-label">Plan</label>
                <input
                  type="text"
                  value={subscription.plan}
                  readOnly
                  className="subscription-info-input"
                />
              </div>
              {subscription.plan !== "Free" && (
                <>
                  <div className="subscription-info-item">
                    <label className="subscription-info-label">
                      Start Date
                    </label>
                    <input
                      type="text"
                      value={subscription.startDate}
                      readOnly
                      className="subscription-info-input"
                    />
                  </div>
                  <div className="subscription-info-item">
                    <label className="subscription-info-label">
                      Renewal Date
                    </label>
                    <input
                      type="text"
                      value={subscription.renewalDate}
                      readOnly
                      className="subscription-info-input"
                    />
                  </div>
                </>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="notification-page">
              <h2 className="notification-title">Notification</h2>
              <div className="notification-info">
                {notifications.length > 0 ? (
                  <div className="notification-card">
                    {notifications.map((notification) => (
                      <div key={notification.id} className="notification-item">
                        <input
                          type="checkbox"
                          checked={notification.isChecked}
                          onChange={() => handleCheckboxChange(notification.id)}
                        />
                        <span className="notification-message">
                          {notification.message}
                        </span>
                        <span className="notification-time">
                          {notification.time}
                        </span>
                        <button
                          className="delete-button"
                          onClick={() => handleDelete(notification.id)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-notification">There are no notifications</p>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="help-page">
              <h2 className="help-title">Help</h2>
              <div className="help-info">
                <p className="help-paragraph">
                  If you need help, please contact support{" "}
                  <span className="email-highlight">
                    <a href="mailto:contactus@antsai.in">contactus@antsai.in</a>
                  </span>
                  .
                </p>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;
