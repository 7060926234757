// ThankYou.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankYou.css'; // Import the custom CSS file for styling
import GreenTickAnimation from '../../assets/Animation - 1729061747362.webm'; // Import the animation

function ThankYou() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/analyser');
    };

    return (
        <div className="thank-you-container">
            <div className="thank-you-content">
                {/* Embed the animation video and play it only once */}
                <video 
                    src={GreenTickAnimation} 
                    className="thank-you-animation" 
                    autoPlay 
                    muted
                />
                <h2>Thank you for your order!</h2>
                <p>Once your payment has been Verified and confirmed. An activation code will be sent to your email ID.</p>
                <button className="thank-you-button" onClick={handleGoBack}>Go to Home</button>
            </div>
        </div>
    );
}

export default ThankYou;
